*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin: 0;
  text-align: center;
}

a {
  color: #2a6ace;

  &:hover {
    color: #0c58cf;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 90%;
  min-height: 100vh;
  margin: 0 auto;
}

.quote {
  margin-top: 0;
  font-size: calc(100% + 5vw);
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10rem;
  max-width: 600px;
}

.ticker-icon {
  display: block;
  position: absolute;
  left: -46px;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
}

.select-wrapper {
  position: relative;
  width: 20%;
  max-width: 20%;
}

.select {
  display: inline-block;
  font-size: 2rem;
  font-family: inherit;
  text-align: left;
  color: #444;
  line-height: 1;
  padding: 0.825rem 0.25rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  border-bottom: 4px solid #444;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWRvd24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWRvd24gZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yMDcuMDI5IDM4MS40NzZMMTIuNjg2IDE4Ny4xMzJjLTkuMzczLTkuMzczLTkuMzczLTI0LjU2OSAwLTMzLjk0MWwyMi42NjctMjIuNjY3YzkuMzU3LTkuMzU3IDI0LjUyMi05LjM3NSAzMy45MDEtLjA0TDIyNCAyODQuNTA1bDE1NC43NDUtMTU0LjAyMWM5LjM3OS05LjMzNSAyNC41NDQtOS4zMTcgMzMuOTAxLjA0bDIyLjY2NyAyMi42NjdjOS4zNzMgOS4zNzMgOS4zNzMgMjQuNTY5IDAgMzMuOTQxTDI0MC45NzEgMzgxLjQ3NmMtOS4zNzMgOS4zNzItMjQuNTY5IDkuMzcyLTMzLjk0MiAweiI+PC9wYXRoPjwvc3ZnPg==');
  background-repeat: no-repeat, repeat;
  background-position: right 0.25rem top 50%, 0 0;
  background-size: 0.65rem auto, 100%;

  @media (max-width: 580px) {
    background-image: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #666;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  option {
    font-weight: normal;
  }
}

.arrow {
  padding: 0 1em;
  font-size: 2rem;
  margin: 0;
}

.info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 5% 2rem;
}

.info-section {
  width: 90%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

.page {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  overflow-y: auto;

  &.is-open {
    display: block;
  }
}

.page-container {
  margin: 4rem auto;
  width: 90%;
  max-width: 960px;
}

.close-page {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 2rem;
  font-weight: 300;
  color: #000;
  cursor: pointer;
}

.supported-tickers {
  list-style: none;
  padding-left: 0;

  li {
    display: inline-block;
    margin: 0.5em;
  }

  a {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: #e4eeff;
    border-radius: 8px;
    text-decoration: none;
  }
}
